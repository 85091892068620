@import url('https://fonts.googleapis.com/css2?family=PT+Serif&display=swap');

@font-face {
  font-family: "Glass Houses";
  src: local("Glass Houses"),
  url("./fonts/glashou.ttf") format("truetype");
}

.font_glass, h1, h2, h3, h4, h5, h6 {
  font-family: "Glass Houses";
}

body,html {
  margin:0;
  padding:0;
}

body {
  font-family: 'PT Serif';
  position: relative;
}

a {
  color:red;
  text-decoration: none;
  transition:0.3s;
}

a:hover {
  color: red;
  text-decoration: underline;
}

#bg {
  background-image: url("./images/blix.jpg");
  background-position: center;
  height: 100vh;
  width: 100vw;
  position:fixed;
  z-index: -1;
  top:0;
  animation-name: zoomBg;
  animation-duration: 100s;
  animation-iteration-count: infinite;
  transition: transform ease 10s;
}

@keyframes zoomBg {
  0% {transform: scale(1);}
  10% {transform: scale(1.1);}
  20% {transform: scale(1.2);}
  30% {transform: scale(1.3);}
  40% {transform: scale(1.4);}
  50% {transform: scale(1.5);}
  60% {transform: scale(1.6);}
  70% {transform: scale(1.7);}
  80% {transform: scale(1.8);}
  90% {transform: scale(1.9);}
  100% {transform: scale(2);}
}

.flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.autoMargin {
  margin: auto;
  display: inline-block;
}

.fullWidth {
  width:100%;
}

header .section {
  background: transparent;
}

.section {
  background-color:#fff;
  background-image: url('./images/assets/texture_light3.jpg');
  background-repeat: repeat;
  color:#000;
  padding:35px 25px;
  margin:0;
  text-align:center;
}

.section_dark.section {
  background-color:#000;
  background-image: url('./images/assets/texture_dark.jpg');
  background-repeat: repeat;
  color:#fff;
}

.section_inner {
  margin: 50px 0 100px 0;
}

.section h2, .section h3, .section h4 {
  background-color:rgba(255, 255, 255, 0.5);
}

.section_dark.section h2, .section_dark.section h3, .section_dark.section h4 {
  background-color:rgba(0, 0, 0, 0.5);
}

#contact, .menu_button_wrapper_contact {

}

#container {
  width:100%;
  overflow:hidden;
  margin-top:5%;
}

#header {
  min-height:300px;
  color:white;
}

.header_title {
  font-size:5em;
  margin:0;
  padding:5% 5% 1% 5%;
}

.header_tagline {
  margin:0;
  padding:0 5% 5% 5%;
  font-size:1.5em;
}

#menu {
  background-color: red;
  padding:5px;
  font-size:1.2em;
  position:fixed;
  top:0;
  width:100%;
}

#menu_bar {
  display:flex;
  align-items:center;
  justify-content: center;
  flex-wrap: nowrap;
  max-width:1000px;
  margin:0 auto;
  padding:0 5px;
}

#menu_inner {
  display:none;
  text-align:center;
}

.menu_title {
  color: white;
}

.menu_title a, .menu_title a::selection{
  color: #fff;
  text-decoration: none;
}

.menu_button_wrapper {
  display:inline-block;
}

.menu_button {
  color: white;
  border: 1px solid white;
  background-color:transparent;
  padding: 10px;
  margin: 5px 5px 4px 5px;
  text-align:center;
  transition: 0.5s;
  display:inline-block;
  font-size: 14px;
}

#menu button:hover, #menu a:hover {
  background-color:white!important;
  color:red;
  text-decoration:none!important;
  cursor:pointer;
}

table {
  background-color:#fff;
  background-image: url('./images/assets/texture_light3.jpg');
  background-repeat: repeat;
  color:#000;
  margin: auto;
  width: 100%;
  border-spacing: 0;
  font-size: 1em;
}

table tr th {
  background-color: red;
  color: #fff;
  padding: 2px;
}

table tr td {
  font-weight: bold;
  border: 2px solid #000;
}

form {
  max-width: 600px;
}

form div {
  text-align: left;
}

form div label {
  font-weight: bold;
}

form div textarea {
  height: 8em;
}

form div input, form div textarea {
  width: 100%;
  resize: none;
}

form div:last-child {
  text-align: right;
}

form div:last-child input {
  width: auto;
  border: none;
  box-shadow: none;
  background-color: red;
  color:#fff;
  padding: 7px 9px;
  border-radius: 3px;
  cursor: pointer;
  font-weight: bold;
  font-size: 1.2em;
  font-family: "Glass Houses";
  transition: 0.2s;
}

form div:last-child input:hover {
  background-color: #000;
}

@media screen and (min-width: 1600px) {
  .menu_button_wrapper_Photos {
    display:none;
  }

  #menu_mobile_button {
    display:none;
  }

  #menu_inner {
    display:inline-block;
    width:50vw;
    padding:0;
    text-align:center;
  }

  #menu_bar {
    display:inline-block; 
    margin-left:25vw;
  }
}

#content {
  display:flex;
  flex-wrap: wrap;
  justify-content: center;
}

#content_left {
  max-width:1000px;
  margin:10px auto 100px auto;
  overflow:hidden;
  background-color: #000;
}

#content_right {
  max-width:600px;
  margin:10px auto 100px auto;
  text-align:center;
}

.content_right_img {
  width:100%;
  display:inline-block;
  margin:10px auto;
}

@media screen and (max-width: 550px) {
  .section_inner div .videoWrapper iframe {
    width:280px;
    height:157px;
  }

  table {
    font-size: 0.6em;
  }
}

.singleDayPass {
  margin: 100px 0;
  padding: 25px;
  border: 1px solid #fff;
}

.singleDayPass img {
  max-width: 100%;
}
.SingleDayPaymentMethods {
  display: flex;
  justify-content: center;
}

.SingleDayPaymentMethods li{
  text-align: left;
}

#paymentMethods {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

#paymentMethods ul {
  text-align:left;
}

.paymentMethod {
  max-width: 500px;
}

.ticket {
  max-width: 360px;
  background-image: url('./images/assets/texture_light3.jpg');
  background-color: #fff;
  color: #000;
  padding:10px;
  margin:10px;
}

.ticket h3 {
  background-color: transparent!important;
}

.ticket ul {
  margin:0;
  padding:0;
}

.ticket ul li {
  list-style: none;
  display:inline-block;
  margin:5px 10px;
}

.ticket img {
  max-width:100%;
}

.ticket form {
  margin: 20px;
}

#flyer2024 {
  width:100%;
  max-width:600px;
}

.oldFlyerWrapper {
  border: 5px solid transparent;
  width: 190px;
  height: 300px;
  display: inline-block;
}

.oldFlyer {
  width:100%;
}

.info_inner_section {
  max-width:1000px;
}

#bottom {
  min-height:200px;
}

#footer {
  background-color:red;
  width:100%;
  bottom:0;
  color:white;
  text-align:center;
}

#footer_inner {
  padding:10px;
}

#footer_cred {
  margin: 0;
}

#footer a {
  color:white;
  text-decoration: underline;
}

#lineUp_container {
  text-align:center;
}

#lineUp {
  display:flex;
  width:100%;
  flex-wrap:wrap;
  justify-content: center;
}

.band_container {
  max-width:333px;
  margin:10px;
  padding:10px 0;
}

.band_logo {
  background-repeat: no-repeat;
  background-size:contain;
  background-position:center;
  width:100%;
  min-height:100px;
}

#ater {
  background-image: url("./images/logos/2024/ater.png");
}

#auxilio {
  background-image: url("./images/logos/2024/auxilio.png");
}

#axefear {
  background-image: url("./images/logos/2024/axefear.png");
}

#instinctofsurvival {
  background-image: url("./images/logos/2024/instinct-of-survival.png");
}

#axehelvete {
  background-image: url("./images/logos/2024/axehelvete.png");
}

#balmsquad {
  background-image: url("./images/logos/2024/balmsquad.png");
}

#bootlicker {
  background-image: url("./images/logos/2024/bootlicker.png");
}

#chueko {
  background-image: url("./images/logos/2024/cheuko.png");
}

#crucifiedclass {
  background-image: url("./images/logos/2024/crucified-class.png");
}

#disposal {
  background-image: url("./images/logos/2024/disposal.png");
}

#ego {
  background-image: url("./images/logos/2024/ego.png");
}

#extensiveslaughter {
  background-image: url("./images/logos/2024/extensive-slaughter.png");
}

#generacionsuicidia {
  background-image: url("./images/logos/2024/generacion-suicida.png");
}

#genogeist {
  background-image: url("./images/logos/2024/genogeist.png");
}

#ilusión {
  background-image: url("./images/logos/2024/ilusion.png");
}

#prowlers {
  background-image: url("./images/logos/2024/prowlers.png");
}

#psychwar {
  background-image: url("./images/logos/2024/psych-war.png");
}

#puffer {
  background-image: url("./images/logos/2024/puffer.png");
}

#recklessupstarts {
  background-image: url("./images/logos/2024/reckless-upstarts.png");
}

#repair {
  background-image: url("./images/logos/2024/repair.png");
}

#spirokete {
  background-image: url("./images/logos/2024/spironkete.png");
}

#ultrarazzia {
  background-image: url("./images/logos/2024/ultra-razzia.png");
}

#voltage {
  background-image: url("./images/logos/2024/voltage.png");
}

#zeal {
  background-image: url("./images/logos/2024/zeal.png");
}

#phane {
  background-image: url("./images/logos/2024/phane.png");
}

#deadcells {
  background-image: url("./images/logos/2024/dead-cells.png");
}

#deathknell {
  background-image: url("./images/logos/2024/deathknell.png");
}

#toytiger {
  background-image: url("./images/logos/toy_tiger.png");
}

#krash {
  background-image: url("./images/logos/krash.png");
}